.ant-collapse.nested-details-collapse {
  .ant-collapse-header {
    padding: 6px 16px;

    .ant-collapse-arrow {
      float: right;
    }
  }
}

.ant-collapse.outer-details-collapse {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;

    .ant-collapse-header {
      border-top: 0px;
      border-bottom: 0px;

      .ant-collapse-arrow {
        display: none;
      }
    }
  }
}
