@import "../../style/constants.scss";

.home_page {
  background-color: $bg-grey;
  padding: 16px;
  display: flex;
  align-items: stretch;

  gap: 16px;

  .col1 {
    width: 450px;
    min-width: 450px;
  }

  .col2 {
    width: 100%;
  }

  .ant-card {
    margin-bottom: 16px;
  }
}
