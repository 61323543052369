.main-navigation {
  overflow: auto;
  height: 100%;

  .menu-logo {
    position: fixed;
    z-index: 1;
  }

  .main-navigation-content {
    padding-top: 96px;
  }

  &.navigation-expanded {
    .menu-toggler {
      text-align: center;
    }

    font-weight: bold;
  }

  .ant-menu-inline .ant-menu-submenu {
    padding: 0;

    .anticon {
      font-size: 18px;
      min-width: 18px;
      color: inherit;
    }
  }

  .ant-menu-inline .ant-menu-item {
    text-overflow: unset;

    .anticon {
      font-size: 18px;
      min-width: 18px;
      color: inherit;
    }
  }

  .ant-menu-submenu-title .anticon {
    font-size: 18px;
    min-width: 18px;
  }

  li {
    padding: 16px;
  }
}
