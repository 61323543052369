@import "../../../style/constants.scss";

.invitation-alert {
  .settings-alert {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 10px 0 10px;
  }

  .settings-alert .ant-alert {
    display: inline-flex;
  }
}