@import "../../../../../style/constants.scss";

.collection-management-panel {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-list-sm .ant-list-item {
    padding: 4px;
  }

  .details-src-item {
    width: 100%;
    display: flex;

    .ant-select {
      width: 100%;
    }

    .item {
      min-width: 160px;
      padding-left: 4px;
    }

    .source {
      min-width: 160px;
      padding-left: 4px;
    }

    .inventory {
      width: 67px;
      padding-left: 4px;
    }

    .actions {
      width: 57px;
      display: block;
      color: $blue;
      margin-left: 30px;

      button {
        padding: 4px;
      }

      .delete {
        font-size: 18px;
        color: $red;
      }

    }
  }

  .ant-list-header {
    background-color: $bg-grey;

    .inventory {
      padding-left: 20px;
      width: 87px;
      font-weight: 600;
    }

    .item {
      padding-left: 8px;
      font-weight: 600;
    }

    .source {
      padding-left: 8px;
      font-weight: 600;
    }

    .actions {
      width: 60px;

      button {
        padding: 1px 7px;
      }
    }
  }

  .source-ddl {
    min-width: 160px !important;
    max-width: 160px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
