.table-with-groups {

  .header-row {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }

  .filter-bar-container {
    flex-grow: 1;
    margin-left: 8px;
  }

  .view-selector-container {
    position: absolute;
    top: -55px;
    right: 5px;
  }

  .select-columns-icon svg {
    width: 25px;
    height: 25px;
  }
}
