@import "./constants.scss";

.group-table-header {
  th.actions-column,
  td.actions-column {
    padding: 0px !important;
  }

  .ant-table-tbody {
    display: none;
  }
}

.group-table-collapse {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .padding_2 {
    padding-left: 24px;
  }

  .right-text {
    float: right;
  }

  .header-totals-text {
    font-weight: 700;
    width: 400px;
  }

  .header-totals-text-only {
    font-weight: 700;
    text-align: left;
    gap: 0;

    .ant-space-item span {
      display: block;
    }
  }

  .header-totals-text-right {
    width: 100%;
    display: block;
    text-align: right;
  }
}

.common-table {
  td.actions-column {
    padding: 0px !important;
  }

  .btn-table-actions {
    font-size: 24px;
    color: $blue;
    display: none;

    &.delete {
      color: $red;
    }

    &.disabled {
      color: $grey;
      cursor: not-allowed;
    }
  }

  .ant-table-row:hover .btn-table-actions {
    display: inline;
  }

  .top-actions .btn-table-actions {
    display: inline;
    font-size: 18px;
  }
}

.dates-column {
  background-color: $bg-dark-grey !important;
}

.warning-icon.anticon {
  color: rgba(0, 0, 0, 0.65);
}

.error-icon.anticon {
  color: $red-5;
}

.info-icon.anticon {
  color: $blue;
}

.col-50px {
  width: 50px;
  display: inline-block;
}

.col-110px {
  width: 110px;
  display: inline-block;
}

.ant-table-body > div {
  margin-bottom: 80px;
}

.ant-collapse .ant-table-body > div {
  margin-bottom: 0;
}

.no-wrap {
  overflow-wrap: normal;
}

