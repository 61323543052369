@import "../../../../style/constants.scss";

.resource-management-panel {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-list-sm .ant-list-item {
    padding: 4px;
  }

  .details-src-item {
    width: 100%;
    display: flex;

    .ant-select {
      width: 100%;
    }

    .source {
      min-width: 160px;
      padding-left: 4px;
    }

    .inventory {
      width: 67px;
    }

    .cost {
      width: 80px;
    }

    .unit {
      flex: auto;
      //width: 120px !important;
      min-width: 80px !important;
      max-width: 80px !important;
    }

    .details {
      width: 120px;
    }

    .actions {
      width: 57px;
      display: block;
      color: $blue;

      button {
        padding: 4px;
      }

      .delete {
        font-size: 18px;
        color: $red;
        margin-left: 8px;
      }

      .details {
        font-size: 18px;
        color: $grey;
      }
    }
  }

  .ant-list-header {
    background-color: $bg-grey;

    .inventory {
      padding-left: 20px;
      width: 87px;
      font-weight: 600;
    }

    .cost-unit {
      padding-left: 20px;
      font-weight: 600;
      min-width: 170px !important;
      width: 170px !important;
    }

    .source {
      padding-left: 8px;
      font-weight: 600;
      min-width: 187px !important;
      width: 187px;
    }

    .details {
      font-weight: 600;
    }

    .actions {
      width: 60px;

      button {
        padding: 1px 7px;
      }
    }
  }

  .source-ddl {
    min-width: 187px !important;
    max-width: 187px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
