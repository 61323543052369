@import "../../../../style/constants.scss";

.filter-bar {
  .btn-reset {
    color: $black;
  }

  position: relative;

  .group-by-filter {
    float: right;
  }

  .columns-visibility {
    float: right;

    button {
      padding: 2px;
    }
  }
}

.ant-tabs.ant-tabs-top.ant-tabs-card .filter-bar .group-by-filter {
  top: 1px;
}
