@import "../../../../style/constants.scss";

.btn-single-items-filter.ant-dropdown-trigger {
  color: $black;
  max-width: 170px;

  .filter-text {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filter-text-down.anticon {
    vertical-align: 0.4em;
  }

  .btn-reset {
    float: right;
  }
}

li.ant-menu-item.filter-search-item.ant-menu-item-only-child {
  padding-left: 0px;
}

.single-item-filter-menu {
  li.ant-menu-item:not(:last-child) {
    margin: 0;
  }
}
