.report-panel-title {
  display: flex;
  justify-content: space-between;
  width: 810px;

  .report-panel-buttons {
    display: flex;
    gap: 10px;
  }
}
