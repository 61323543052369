.notes-details-panel {
  .linked-report {
    display: flex;
    align-items: center;
    gap: 5px;

    .linked-report-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
