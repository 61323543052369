.ant-table-summary {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background-color: transparent;
  border: 3px solid #d9d9d9 !important;
  border-collapse: initial !important;
  border-width: 3px 0 !important;
  border-radius: 2px;
  font-weight: 600;
}

.ant-collapse-header {
  .ant-collapse-extra {
    margin-left: 0px !important;
  }

  .padding_200 {
    min-width: 180px !important;
    width: 184px;
  }
}


