@font-face {
  font-family: MarkWeb-Light;
  src: url("FFMarkWeb/MarkWeb-Light.eot"), url("./FFMarkWeb/MarkWeb-Light.woff") format("woff");
}

@font-face {
  font-family: MarkWeb-Book;
  src: url("FFMarkWeb/MarkWeb-Book.eot"), url("./FFMarkWeb/MarkWeb-Book.woff") format("woff");
}

@font-face {
  font-family: MarkWeb;
  src: url("FFMarkWeb/MarkWeb.eot"), url("./FFMarkWeb/MarkWeb.woff") format("woff");
}
