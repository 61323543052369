.ant-form-item.ant-form-item-without-validation {
  margin-bottom: 5px;
}

.ant-form-item.double-line-label .ant-form-item-label {
  white-space: normal;

  & > label::after {
    content: "";
  }
}

.form-item-label-icon {
  padding: 3px 3px 5px 0;
}
