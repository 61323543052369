@import "../../../style/constants.scss";

.todays-notes {
  .ant-list-header {
    background-color: $bg-grey;
    font-weight: 600;
  }
}

.home-notes {
  .notes-project {
    width: 200px;
  }

  .notes-text {
    width: 100%;
  }

  .notes-action {
    width: 30px;
  }
}
