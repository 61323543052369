.details-panel, .sub-details-panel {
  .ant-drawer-body {
    padding: 0;
    padding-bottom: 80px;

    .side-panel {
      padding: 16px;

      &.with-collapse {
        padding: 0;
      }
    }

    .divider-first {
      margin-top: 0px;
    }
  }

  .ant-drawer-header {
    padding: 8px;

    .ant-drawer-title > .anticon, .details-panel-title-icon {
      font-size: 26px;
      width: 26px;
      height: 26px;
      margin-right: 3px;
      vertical-align: middle;
    }

    .notes-details-panel-title {
      max-width: 410px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
 
