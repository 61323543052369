@import "../../../../style/constants.scss";

.btn-multiple-items-filter.ant-dropdown-trigger {
  color: $black;

  max-width: 170px;

  .filter-text {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filter-text-down.anticon {
    vertical-align: 0.4em;
  }

  .active-filter {
    color: $blue;
  }
}

.multiple-items-filter-menu {
  li.ant-menu-item.filter-search-item.ant-menu-item-only-child {
    padding-left: 0px;
  }

  li.ant-menu-item:not(:last-child) {
    margin: 0;

    &.padding_2 {
      padding-left: 40px;
    }
  }

  li.bottom-buttons.ant-menu-item:active {
    background: inherit;
  }

  .grouped-menu-items {
    max-height: 400px;
    overflow: scroll;
  }

  .grouped-menu-items > .ant-menu-item-group-title {
    display: none;
  }

}
