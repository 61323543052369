@import "../../../../style/constants.scss";

.add-ruleset-modal {
  .form-item {
    padding: 8px;
    margin-bottom: 0px;
  }

  .divider-first {
    margin-top: 16px;
    margin-bottom: 5px;
  }

  .input-number {
    width: 60px;
  }

  .ruleset-name-input {
    width: 220px;
  }

  .ant-form-item-label > label {
    color: $medium-light-gray !important;
  }

  .form-item span {
    color: $medium-light-gray !important;
  }

  .ant-form-item-children-icon {
    left: 190px;
  }
}