.ant-menu.goto_menu > .ant-menu-item {
  height: 30px;
  line-height: 30px;
}

.recently-updated-projects {
  .go-to {
    float: right;
  }

  .project-status-badge-selector.project-status-badge {
    margin-top: 0px;
  }
}
