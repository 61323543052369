.btn-multiple-items-filter.ant-dropdown-trigger {
  max-width: 170px;

  .filter-text {
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .filter-text-down.anticon {
    vertical-align: 0.4em;
  }
}

li.ant-menu-item.filter-search-item.ant-menu-item-only-child {
  padding-left: 0px;
}

.ant-picker-dropdown.date-range-dates.ant-picker-dropdown-range.ant-picker-dropdown-placement-bottomLeft {
  padding: 0;
  top: 0px !important;

  .ant-picker-range-arrow {
    display: none;
  }
}

.date-range-holder {
  top: 0px;
  position: relative;
}

.btn-reset {
  float: right;
}

.date-range-filter {
  animation: none !important;
}

.ant-dropdown-menu-item.date-range-menu-item {
  padding: 0px;
}