@import "../../../style/constants.scss";

.reset-sync,
.reset-sync:active,
.reset-sync:hover {
  color: $grey;
  padding: 3px;
}

.date-grey input {
  color: $grey;
}

.enable-bookings-confirm {
  &.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
    color: $default-grey;
  }

  .ant-modal-confirm-title {
    font-weight: 600;
  }
}
