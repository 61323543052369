@import "../../style/constants.scss";

.instructor-dashboard {
  background-color: $bg-grey;
  padding: 16px;
  gap: 16px;

  .columns-container {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
  }

  .portfolio_selector {
    width: 450px;
  }
}