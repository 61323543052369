.user_profile {
  padding-left: 4px;
  width: 60px;
  height: 60px;

  .ant-avatar {
    padding: 3px;
    display: inline-block;
    width: 50px;
    position: absolute;
    top: -10px;
    cursor: pointer;

    & > img {
      display: inline;
      height: auto;
    }
  }
}

.user_profile_modal {
  .ant-modal-title {
    h3 {
      display: inline-block;
    }

    img {
      padding: 3px;
      display: inline-block;
    }

    .ant-avatar {
      margin-right: 3px;

      & > img {
        display: inline;
        height: auto;
      }
    }
  }
}