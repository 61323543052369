.duration-shortcuts {
  .row-margin {
    margin-bottom: 0;
  }

  .form-item {
    margin-bottom: 8px;
  }

  .row {
    margin: 0 -12px;
  }

  .col {
    padding: 0 0 0 12px;
  }

  .input-number {
    width: 71px;
  }
}