.url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 265px;
  display: inline-block;
  align-items: center;
  padding-top: 3px;

  &.disabled {
    cursor: not-allowed;
  }
}

.url-copy {
  margin-bottom: 4px;
  vertical-align: top;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.pages-block {
  display: flex;
  flex-wrap: wrap;
}
