@import "../../../style/constants.scss";

.view-edit-list {
  .btn-edit-row {
    font-size: 18px;
    float: right;
    display: none;
    color: $blue;
  }

  .view-row-item:hover .btn-edit-row {
    display: block;
    float: right;
  }

  &.edit-mode {
    background-color: $bg-blue;
    padding: 5px;
  }

  .ant-row {
    margin-bottom: 5px;
  }

  .btn-remove-row-holder {
    font-size: 18px;
    color: $red;
    text-align: right;

    .btn-remove-row {
      vertical-align: baseline;
      margin-right: 5px;
    }

    &.disabled {
      color: $grey;
    }
  }

  .btn-actions-holder {
    text-align: right;
  }

  .view-row-item-type {
    text-align: right;

    span {
      margin-right: 8px;
    }
  }
}
