.settings-blocks {
  padding: 0 16px;

  .ant-card {
    width: 500px;
  }
}

.ant-select-item-option-disabled .anticon {
  color: rgba(0, 0, 0, 0.25)
}
