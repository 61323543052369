.projects-multiple-selector {
  &.ant-btn,
  &.ant-btn:hover {
    font-size: 24px;
    line-height: 24px;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
  }

  &.ant-menu-vertical .ant-menu-item {
    height: 26px;
    line-height: 26px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  li.ant-menu-item.filter-search-item.ant-menu-item-only-child {
    padding-left: 16px;
  }

  .project-status-badge {
    float: right;
  }
}
