.quick-info-content {

  .event-content {
    display: flex;
    flex-direction: column;

    .row {
      display: flex;

      .column {
        padding: 5px;
      }

      .label {
        font-weight: bold;
        min-width: 100px;
      }

      .value {
        flex: 1;
      }
    }

    .details-scroll {
      max-height: 150px;
      overflow-y: auto;
    }

    .contacts-scroll {
      max-height: 100px;
      overflow-y: auto;
    }
  }
}