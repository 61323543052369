@import './DateRangeCommon.scss';

.date-range-selector.ant-dropdown-trigger {
  max-width: 250px !important;
  display: flex;
  align-items: center;
  border-right-color: grey;

  .filter-text {
    font-size: 14px;
    max-width: 210px !important;
  }
}