.enable-form-item {
  align-items: center;
  height: 32px;
  display: inline-flex;
}

.long-label {
  .ant-form-item-label {
    white-space: normal;

    & > label {
      height: 42px;

      &::after {
        top: 10px;
      }
    }
  }
}
