.merge_contact {
  .into {
    display: inline;
  }

  .person_selection {
    width: 150px;
  }

  .ant-card-type-inner .ant-card-head-title {
    padding: 4px 0px;

    .anticon {
      font-size: 26px;
      width: 26px;
      height: 26px;
      margin-right: 3px;
      vertical-align: middle;
    }
  }
}
