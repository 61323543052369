.detail_panels_avatar.ant-avatar {
  margin: 3px;
  vertical-align: middle;

  & > img {
    display: inline;
    height: auto;
  }
}

.contact-details-panel-projects {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }
}
