@import "../../../style/constants.scss";

.crew-management-panel {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-list-sm .ant-list-item {
    padding: 4px;
  }

  .details-crew-item {
    width: 100%;
    display: flex;

    .ant-select {
      width: 100%;
    }

    .status {
      width: 57px;
    }

    .name {
      flex: auto;
      width: 170px;
      padding-left: 4px;
    }

    .role {
      width: 150px;
      padding-left: 4px;
    }

    .actions {
      button {
        padding: 4px;
      }

      width: 57px;
      display: block;

      color: $blue;

      .delete {
        font-size: 18px;
        color: $red;
      }

      .details {
        font-size: 18px;
        color: $grey;
      }
    }
  }

  .ant-list-header {
    background-color: $bg-grey;

    .status {
      padding-left: 20px;
      font-weight: 600;
    }

    .name {
      padding-left: 8px;
      font-weight: 600;
    }

    .role {
      padding-left: 8px;
      font-weight: 600;
    }

    .actions {
      width: 60px;

      button {
        padding: 1px 7px;
      }
    }
  }
}

.status-ddl {
  width: 120px !important;
  min-width: 120px !important;
}
