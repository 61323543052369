@import "../../../../style/constants.scss";

.modal-import {
  .border-left-3 {
    border-left: 3px solid $bg-dark-grey;
    padding-left: 5px;
  }

  .required-field-error {
    margin: 5px 0;
  }
}
