.time-range-picker {
  .ant-picker-footer-extra {
    border-bottom: 0;
    padding: 0px;

    .interval-buttons {
      button {
        padding: 3px;
      }
    }
  }

  .ant-picker-ranges {
    display: none;
  }
}

