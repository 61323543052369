.project-selector-menu {
  width: 300px;

  &.ant-menu-vertical .ant-menu-item {
    height: 26px;
    line-height: 26px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  .project-status-badge {
    float: right;
  }

  li.ant-menu-item.filter-search-item.ant-menu-item-only-child {
    padding-left: 16px;
  }

  .date-picker-without-input {
    .ant-picker-input {
      display: none;
    }
  }
}

.project-selector.selected-project {
  cursor: pointer;
  padding-right: 0px;
  padding-left: 0px;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.65);

  &:hover {
    color: rgba(0, 0, 0, 0.65);
  }

  .anticon.anticon-down {
    font-size: 16px;
  }
}
