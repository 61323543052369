.tags-filter {
  &.ant-btn,
  &.ant-btn:hover {
    font-size: 24px;
    line-height: 24px;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
  }

  &.ant-btn > span {
    display: inline;
  }

  &.ant-menu-vertical .ant-menu-item {
    height: 26px;
    line-height: 26px;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

}
