@import "../../../style/constants.scss";

.student-table {
  .bold-header th {
    font-weight: bold;
  }
}

.ant-card {
  margin-bottom: 16px;
}

.ant-table-pagination.ant-pagination {
  margin-right: 15px;
}