@import "../../style/constants.scss";
@import "../../style/table.scss";
@import "../../style/collapse.scss";

.App {
  .ant-layout-header,
  .ant-layout-content,
  .ant-layout-footer,
  .ant-layout-sider {
    background-color: #fff;
  }

  .ant-layout-header {
    padding: 20px 20px 20px;
    line-height: unset;
    height: auto;
    max-height: 115px;
  }

  .no-selected-menu-item {
    background-color: none;
  }

  .batch-selection-header {
    background-color: $bg-blue2;
  }

  .ant-anchor-wrapper {
    padding-left: 0;

    &.header-anchor {
      margin-left: 0;
    }
  }

  .left-layout-panel {
    background-color: #1d97b3;
  }

  .main-content-layout {
    z-index: 1;
  }

  .html-content p {
    margin: 0;
    padding: 0;
  }
}
