@import "../../../../style/constants.scss";

.labour-details-panel,
.booking-details-panel {
  .goto-event.goto-event-labour {
    right: 0px;
    top: -6px;
    position: absolute;
  }

  .reset-cost {
    display: flex;
    align-items: flex-end;
  }

  .no-time {
    font-style: italic;
  }

  .selected-task {
    max-width: 250px;
    width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
  }

  .booking-status-icon {
    padding: 0 5px;
  }

  .cost-header {
    padding-left: 12px;
  }

  .calculated-label {
    position: absolute;
  }
}
