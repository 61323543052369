$primary-color: #0082a6;
$today-day-color: #006080;

.schedule-view {
  font-family: 'MarkWeb', sans-serif !important;
  height: calc(100vh - 202px);
  width: 100%;
  display: flex;
  flex-direction: column;

  .full-height {
    flex: 1;
    overflow-y: auto;
  }

  .small-margin-right {
    margin-right: 2px;
  }

  .location-wrap {
    white-space: normal;
  }
}

.header-template-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.event-details-inline {
  display: flex;
  align-items: center;
  white-space: normal;
}

.day-event-template-wrap .time {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-highlight {
  display: flex;
  padding-top: 2px;
}

.date-row {
  display: flex;
  align-items: center;
}

.e-control, .e-css, .e-error {
  font-family: 'MarkWeb', sans-serif !important;
}

.e-schedule-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
}

.e-subject {
  color: black !important;
}

.e-header-icon-wrapper button {
  color: black !important;
}

.date-highlight {
  color: #cdcccc;
}

.e-schedule .e-month-view .e-date-header:not(.date-row .e-date-header) {
  display: none !important;
}

.e-schedule .e-month-view .e-appointment .e-appointment-details > div {
  display: contents;
}

.day-event-template-wrap .subject {
  font-size: 14px !important;
}

.e-schedule .e-month-view .e-appointment .e-appointment-details .e-subject {
  font-size: 12px !important;
}

.day-event-template-wrap {
  line-height: 1.3;
}

.event-details-inline .subject.favorite,
.e-subject.e-text-center.favorite {
  font-weight: 700 !important;
}

.e-schedule .e-month-view .e-current-date .e-date-header,
.e-schedule .e-month-agenda-view .e-current-date .e-date-header {
  background: $today-day-color !important;
}

.e-schedule .e-current-day {
  color: inherit !important;
}

.e-quick-popup-wrapper .e-event-popup .e-popup-content {
  padding: 10px 5px 5px 20px !important;
}

.e-quick-popup-wrapper {
  min-width: 435px !important;
}

.e-clone svg {
  color: black;
}

.e-toolbar-right {
  display: none !important;
}

.e-selected.e-focused-date span {
  background-color: $primary-color !important;
}

.e-focused-date.e-today span {
  border: 1px solid $primary-color !important;
  color: white !important;
}

.e-cell.e-today span {
  border: 1px solid $primary-color !important;
  color: black !important;
}

.e-cell.e-selected.e-focused-date.e-today span {
  color: white !important;
}

.e-today {
  color: $primary-color !important;
}

.e-schedule .e-header-cells.e-current-day .e-header-date {
  background-color: $today-day-color !important;
  color: white !important;
  border-radius: 50%;
  width: 29px;
  text-align: center;
}