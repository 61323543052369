@import "../../../../style/constants.scss";

.spaces-panel {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-list-sm .ant-list-item {
    padding: 4px;
  }

  .space-item {
    width: 100%;
    display: flex;

    .title {
      width: 180px;
    }

    .notes {
      flex: auto;
      width: 200px;
      padding-left: 4px;
    }

    .actions {
      button {
        // padding: 4px;
      }

      width: 26px;
      display: block;

      color: $blue;

      .delete {
        font-size: 18px;
        color: $red;
      }
    }
  }

  .ant-list-header {
    background-color: $bg-grey;

    .title {
      padding-left: 20px;
      font-weight: 600;
    }

    .notes {
      padding-left: 8px;
      font-weight: 600;
    }

    .actions {
      width: 110px;
    }
  }
}
