@import "../../../../style/constants.scss";

.departments-notes-panel {
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-form-item.ant-form-item-without-validation {
    margin-bottom: 0px;
  }

  .ant-list-sm .ant-list-item {
    padding: 0;
  }

  .departments-notes-item {
    width: 100%;
    display: flex;
    padding: 4px;

    .notes {
      width: 410px;
    }

    .departments {
      width: 210px;
      padding-left: 4px;
    }

    .track {
      width: 46px;
      padding-left: 14px;
      padding-right: 14px;
    }

    .due-date {
      width: 154px;
      padding-left: 4px;
    }

    .done {
      padding-left: 5px;
    }

    .actions {
      width: 70px;
      display: inherit;

      color: $blue;

      .delete {
        color: $red;
      }

      button span {
        font-size: 18px;
      }
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .group-header {
    padding: 12px;
    width: 100%;
    font-weight: 600;
    background-color: $bg-dark-grey;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .add-button {
      margin-left: 18px;
      margin-right: 700px;
    }
  }

  .ant-list-header {
    background-color: $bg-grey;

    .notes {
      padding-left: 20px;
      font-weight: 600;
    }

    .departments {
      font-weight: 600;
    }

    .track {
      font-weight: 600;
      padding-left: 4px;
      padding-right: 0px;
    }

    .due-date {
      width: 128px;
      font-weight: 600;
      padding-left: 8px;
    }

    .actions {
      width: 85px;

      button span {
        font-size: 12px;
      }
    }
  }
}
