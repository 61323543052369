.ant-modal.shift-common-modal {
  .ant-modal-body {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .mid-size-item {
    padding: 5px 0;
  }
}
