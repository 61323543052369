@import "../../../style/constants.scss";


.timeline-details-panel {
  .details-labour-item {
    width: 100%;
    display: flex;

    .ant-select {
      width: 100%;
    }

    .position {
      width: 300px;
    }

    .qty {
      width: 80px;
    }

    .actions {
      button {
        padding: 4px;
      }

      width: 57px;
      display: block;

      color: $blue;

      .delete {
        font-size: 18px;
        color: $red;
      }

      .details {
        font-size: 18px;
        color: $grey;
      }
    }
  }


  .table-in-panel .event-report-actions {
    padding-left: 13px;

    button {
      padding: 4px;
    }

    width: 75px;
    display: block;

    .delete {
      font-size: 18px;
      color: $red;
    }

    .details {
      font-size: 18px;
      color: $grey;
    }
  }

  .ant-list-header {
    .details-labour-item {
      background-color: $bg-grey;

      .position {
        width: 320px;
        padding-left: 20px;
        font-weight: 600;
      }

      .qty {
        width: 40px;
        padding-left: 8px;
        font-weight: 600;
      }

      .actions {
        width: 120px;

        button {
          padding: 1px 7px;
        }
      }
    }
  }

  .ant-tree-switcher-noop {
    display: none;
  }

  .ant-tree {
    .ant-tree-node-content-wrapper {
      padding: 0;
    }

    .ant-tree-node-content-wrapper:hover {
      background-color: inherit;
    }
  }
}
