@import "../../../style/constants.scss";

.add-labour-lines-modal {
  .ant-modal-body {
    padding: 0 10px 20px;
  }

  .ant-list-header {
    background-color: $bg-grey;
    font-weight: 600;
  }

  .labour-items-header, .labour-items-row {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;

    .position {
      width: 330px;
      padding-left: 5px;
    }

    .actions {
      width: 45px;
    }
  }

  .ant-list-item {
    padding-left: 0px;
    padding-right: 0px;
  }

  .labour-items-row {
    .position {
      width: 370px;
    }

    .qty {
      width: 75px;
    }

    .actions {
      width: 40px;

      .delete {
        font-size: 18px;
        color: $red;
      }
    }

  }

}
