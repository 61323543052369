.ql-picker-options {
  right: 0;
}

.quill-text-editor .ql-editor {
  min-height: 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 12px;
  font-family: MarkWeb, Arial, sans-serif;
}

.quill-disabled {
  &, & button, & span, & .ql-editor, & p,
  & div, & ul, & li, & h1, & h2, & h3, & h4, & h5, & h6,
  & ol {
    background-color: #f5f5f5 !important;
    cursor: not-allowed !important;
  }
}

.ql-snow .ql-tooltip {
  z-index: 1;
}
