.ant-breadcrumb-root {
  font-size: 24px;
  line-height: 32px;

  .anticon {
    font-size: 18px;
  }

  .first-breadcrumb .anticon {
    font-size: 26px;
  }

  & > span:last-child .ant-breadcrumb-separator {
    display: none;
  }

  .projects-multiple-selector {
    max-width: 350px;

    &.btn-multiple-items-filter.ant-dropdown-trigger .filter-text {
      max-width: 330px;
      vertical-align: -0.3em;
    }

    &.btn-multiple-items-filter.ant-dropdown-trigger .active-filter {
      vertical-align: baseline;
    }
  }
}
