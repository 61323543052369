.details-panel-tree-menu {
  width: 310px !important;

  .tree-disable-all-option {
    padding: 8px 8px 4px 24px;
  }

  .ant-menu-item-divider {
    margin-bottom: 4px;
  }
}