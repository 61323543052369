.ant-modal.clone-project-modal {
  .ant-modal-body {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.ant-row.clone-dates-result {
  line-height: 24px;
  padding: 6px 0;
}
