@import "../../../../style/constants.scss";

.req-management-panel {
  border-top: 1px solid #d9d9d9;

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
  }

  .ant-collapse-content > .ant-collapse-content-box > .req-panel-well {
    padding: 16px;
  }

  .ant-list-sm .ant-list-item {
    padding: 4px;
  }

  .button-container {
    text-align: center;
    padding: 10px 0;
  }

  .details-src-item {
    width: 100%;
    display: flex;

    .ant-select {
      min-width: 75px !important;
      display: flex;
    }

    .ant-input-number {
      max-width: 82px !important;
      display: flex;
    }

    .source {
      min-width: 120px;
      max-width: 120px;
      width: 120px;
      padding-left: 4px;
      overflow-wrap: break-word;
    }

    .availability {
      width: 47px;
      padding: 6px 4px 2px 4px;

      .negative {
        color: $red !important;
        font-weight: 600;
      }
    }

    .inventory {
      width: 67px;

      .ant-input-number {
        max-width: 60px !important;
        display: flex;
      }
    }

    .cost {
      width: 90px;

      .ant-input-number {
        max-width: 90px !important;
        display: flex;
      }
    }

    .unit {
      flex: auto;
      //width: 120px !important;
      //min-width: 120px !important;
    }

    .actions {
      width: 57px;
      display: block;
      color: $blue;

      button {
        padding: 4px;
      }

      .delete {
        font-size: 18px;
        color: $red;
      }

      .details {
        font-size: 18px;
        color: $grey;
      }
    }
  }

  .ant-list-header {
    background-color: $bg-grey;

    .inventory {
      padding-left: 20px;
      width: 67px;
      font-weight: 600;
    }

    .availability {
      padding: 0 0 0 10px;
      width: 57px;
      font-weight: 600;
    }

    .cost-unit {
      padding-left: 10px;
      font-weight: 600;
      min-width: 100px !important;
      width: 100px !important;
    }

    .source {
      padding-left: 8px;
      font-weight: 600;
    }

    .actions {
      width: 50px;

      button {
        padding: 1px 7px;
      }
    }
  }

  .source-ddl {
    min-width: 160px !important;
    max-width: 160px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .info-icon,
  .warning-icon,
  .anticon-info-circle.ant-popover-open {
    padding: 4px;
  }
}
