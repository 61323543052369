@import "../../../../style/constants.scss";

.resource-search {
  .search-filter {
    padding: 5px;
  }

  .resource-table-header {
    background-color: $bg-grey;
    padding: 5px 15px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 15px;
  }

  .ant-list-item {
    padding: 5px 0px;
  }

  .fixed-search {
    position: fixed;
    width: 380px;
    background-color: white;
    z-index: 9;
  }

  .resource-search-content {
    padding-top: 75px;
  }
}
