@import "../../../style/constants.scss";

.delete-student-modal {
  &.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
    color: $red;
  }
}

.delete-student-button > .anticon {
  color: $red;
  cursor: pointer;
  margin-right: 16px;
  font-size: 22px;
}
