.btn-filter-detail-panel {
  &.ant-btn {
    height: auto;
    text-align: left;
    white-space: normal;

    span {
      display: inline;
    }
  }
}

.multiple-items-filter-menu.menu-small {
  li.ant-menu-item {
    height: 28px;
    line-height: 28px;

    &.filter-search-item {
      height: 40px;
    }

    .ant-checkbox-wrapper {
      line-height: 14px;
    }
  }
}
