.portfolio_selector {
  .ant-card-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  .ant-list-item {
    padding-left: 16px;

    &.selected {
      background-color: #e6f7ff;
      border-right: 3px solid #1890ff;
    }
  }
}
