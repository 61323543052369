@import "../../../style/constants.scss";

.student-invitation-management-sidebar {

  .management-panel {
    background-color: $bg-white;
    width: 600px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e6e6e6;
  }

  .panel-text {
    color: #ababab;
    margin-right: 10px;
  }

  .signup-link {
    width: 350px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .panel-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .panel-header {
    padding: 15px;
  }

  .copy-link {
    margin-left: 5px;
    cursor: pointer;
  }
}