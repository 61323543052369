.team-member-details {
  .no-contact {
    font-size: 20px;
    padding: 0 80px;
  }

  .contact-block {
    padding-bottom: 8px;
  }
}

.btn-quick-create {
  display: flex;
  padding: 4px;

  button {
    padding: 4px;
    flex: auto;
  }
}

.people-org-ddl {
  width: 315px !important;
  min-width: 315px !important;
}
