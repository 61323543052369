@import "../../style/constants.scss";

.login_content {
  margin: 0px auto;
  width: 340px;
  text-align: center;

  h1 {
    color: $grey;
  }

  .login_content_reset {
    color: $grey;
  }
}
