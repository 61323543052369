.project-status-badge .ant-badge-count {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}

.grouped-projects {
  .ant-collapse-extra {
    width: 100%;
  }

  .group-table-collapse .header-totals-text-only.header-project-total {
    float: right;
    margin-right: 93px;

    .item-cost, .labour-cost, .total-cost {
      width: 100px;
      float: left;
    }
  }

}

.archive-projects .group-table-collapse .header-totals-text-only.header-project-total {
  margin-right: 3px;
}
