.ant-table-summary {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 11px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  background-color: transparent;
  border: 3px solid #d9d9d9 !important;
  border-collapse: initial !important;
  border-width: 3px 0 !important;
  border-radius: 2px;
  font-weight: 600;
}

.ant-collapse-header {
  .padding_250 {
    margin-right: auto !important;
  }

  .header-totals-text {
    margin-right: 295px;
  }
}

